// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "@firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBZEXKeaNTVGHBYMJQOgj3V4b5B_L1IxRw",
  authDomain: "latertots-a6694.firebaseapp.com",
  projectId: "latertots-a6694",
  storageBucket: "latertots-a6694.appspot.com",
  messagingSenderId: "341909213910",
  appId: "1:341909213910:web:5be3c307ff6eafd3846f77",
  measurementId: "G-NHZ0VVLJPY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);

// export app and analytics
export { app, analytics, storage };